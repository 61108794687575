@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes draw {
  from {
    stroke-dashoffset: 100;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.x-underline-color {
  background-size: 100% 15%;
  background-repeat: repeat-x;
  background-position: left 0% bottom 10%;
  background-image: linear-gradient(
    181deg,
    #ffa620 0%,
    #fba11a 50%,
    transparent 54%,
    transparent 100%
  );
}
